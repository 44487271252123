import PropTypes from 'prop-types';
import { useEffect, useCallback, useState, useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
//
import { useAuthContext } from '../hooks';

function PermissionGuard({ children }) {
    const router = useRouter();

    const { permissions } = useAuthContext();
    console.log('permissions', permissions);

    const pathsWithPermissions = useMemo(
        () => ({
            // devices
            [paths.dashboard.devices.root]: permissions?.DeviceManagement?.InventoryChild?.read,
            [paths.dashboard.devices.detail]: permissions?.DeviceManagement?.InventoryChild?.read,
            [paths.dashboard.devices.list]: permissions?.DeviceManagement?.InventoryChild?.read,
            [paths.dashboard.devices.edit]: permissions?.DeviceManagement?.InventoryChild?.edit,
            [paths.dashboard.devices.bulkedit]: permissions?.DeviceManagement?.InventoryChild?.edit,
            [paths.dashboard.devices.create]: permissions?.DeviceManagement?.InventoryChild?.write,
            [paths.dashboard.devices.details]: permissions?.DeviceManagement?.InventoryChild?.read,
            [paths.dashboard.devices.newcreate]:
                permissions?.DeviceManagement?.InventoryChild?.write,

            // profiles
            [paths.dashboard.profiles.root]: permissions?.DeviceSecurity?.ProfilesChild?.read,
            [paths.dashboard.policies.list]: permissions?.DeviceSecurity?.ProfilesChild?.read,
            [paths.dashboard.profiles.list]: permissions?.DeviceSecurity?.ProfilesChild?.read,
            [paths.dashboard.profiles.edit]: permissions?.DeviceSecurity?.ProfilesChild?.edit,
            [paths.dashboard.profiles.create]: permissions?.DeviceSecurity?.ProfilesChild?.write,
            [paths.dashboard.policies.create]: permissions?.DeviceSecurity?.ProfilesChild?.write,
            [paths.dashboard.profiles.detail]: permissions?.DeviceSecurity?.ProfilesChild?.read,
            [paths.dashboard.profiles.workprofile]:
                permissions?.DeviceSecurity?.ProfilesChild?.write,

            // [paths.dashboard.certificates.root]: permissions?.DeviceManagement?.certificateChild.read,

            // device alerts
            // [paths.dashboard.devicealerts.root]:
            //     permissions?.DeviceManagement?.DeviceAlertsChild?.read,
            // [paths.dashboard.devicealerts.edit]:
            //     permissions?.DeviceManagement?.DeviceAlertsChild?.edit,
            // [paths.dashboard.devicealerts.create]:
            //     permissions?.DeviceManagement?.DeviceAlertsChild?.write,

            // os updates
            // [paths.dashboard.osupdates.root]: permissions?.DeviceManagement?.OSUpdatesChild?.read,
            // [paths.dashboard.osupdates.list]: permissions?.DeviceManagement?.OSUpdatesChild?.read,
            // [paths.dashboard.osupdates.edit]: permissions?.DeviceManagement?.OSUpdatesChild?.edit,
            // [paths.dashboard.osupdates.create]:
            //     permissions?.DeviceManagement?.OSUpdatesChild?.write,

            // //schedule device scan
            // [paths.dashboard.scheduledevicescan.root]:
            //     permissions?.DeviceManagement?.ScheduleDeviceScanChild?.write,
            // [paths.dashboard.scheduledevicescan.scannow]:
            //     permissions?.DeviceManagement?.ScheduleDeviceScanChild?.write,

            // Schedule device scan
            [paths.dashboard.scheduledevicescan.root]:
                permissions?.DeviceManagement?.ScheduleDeviceScanChild?.write,
            [paths.dashboard.scheduledevicescan.scannow]:
                permissions?.DeviceManagement?.ScheduleDeviceScanChild?.write,

            // app repository
            [paths.dashboard.apprepository.root]:
                permissions?.ApplicationManagement?.AppCatelogChild?.read,
            [paths.dashboard.apprepository.root2]:
                permissions?.ApplicationManagement?.AppStores?.read,
            [paths.dashboard.apprepository.root3]:
                permissions?.ApplicationManagement?.AppStores?.read,
            [paths.dashboard.apprepository.applist]:
                permissions?.ApplicationManagement?.AppCatelogChild?.read,
            [paths.dashboard.apprepository.publicapps]:
                permissions?.ApplicationManagement?.AppStores?.write,
            [paths.dashboard.apprepository.privateapps]:
                permissions?.ApplicationManagement?.AppStores?.write,
            [paths.dashboard.apprepository.webapps]:
                permissions?.ApplicationManagement?.AppStores?.write,
            [paths.dashboard.apprepository.organizeapps]:
                permissions?.ApplicationManagement?.AppStores?.write,
            [paths.dashboard.apprepository.orgprivatestore]:
                permissions?.ApplicationManagement?.AppStores?.write,
            [paths.dashboard.apprepository.detail]:
                permissions?.ApplicationManagement?.AppCatelogChild?.read,
            [paths.dashboard.apprepository.details]:
                permissions?.ApplicationManagement?.AppCatelogChild?.read,
            [paths.dashboard.apprepository.distribute]:
                permissions?.ApplicationManagement?.AppCatelogChild?.write,

            // app update policy
            // [paths.dashboard.appupdatepolicy.root]: permissions?.ApplicationManagement?.AppCatelogChild?.read,
            // [paths.dashboard.appupdatepolicy.edit]: permissions?.ApplicationManagement?.AppCatelogChild?.edit,
            // [paths.dashboard.appupdatepolicy.list]: permissions?.ApplicationManagement?.AppCatelogChild?.read,
            // [paths.dashboard.appupdatepolicy.create]: permissions?.ApplicationManagement?.AppCatelogChild?.write,

            // enrollments devices
            [paths.dashboard.device.root]: permissions?.DeviceManagement?.EnrollmentChild?.read,
            [paths.dashboard.device.enrolldeviceandroid]:
                permissions?.DeviceManagement?.EnrollmentChild?.write,
            [paths.dashboard.device.enrolldeviceandroid2]:
                permissions?.DeviceManagement?.EnrollmentChild?.read,
            [paths.dashboard.device.enrolldevicedetails]:
                permissions?.DeviceManagement?.EnrollmentChild?.read,
            [paths.dashboard.device.tokenList]:
                permissions?.DeviceManagement?.EnrollmentChild?.read,
            [paths.dashboard.device.tokensTab]:
                permissions?.DeviceManagement?.EnrollmentChild?.read,

            // enrollment groups
            [paths.dashboard.groups.root]: permissions?.DeviceManagement?.GroupsChild?.read,
            [paths.dashboard.groups.list]: permissions?.DeviceManagement?.GroupsChild?.list,
            [paths.dashboard.groups.edit]: permissions?.DeviceManagement?.GroupsChild?.edit,
            [paths.dashboard.groups.create]: permissions?.DeviceManagement?.GroupsChild?.write,
            [paths.dashboard.groups.details]: permissions?.DeviceManagement?.GroupsChild?.read,

            // enrollment device users
            [paths.dashboard.users.root]: permissions?.DeviceManagement?.DeviceUsersChild?.read,
            [paths.dashboard.users.details]: permissions?.DeviceManagement?.DeviceUsersChild?.read,
            [paths.dashboard.users.list]: permissions?.DeviceManagement?.DeviceUsersChild?.read,
            [paths.dashboard.users.edit]: permissions?.DeviceManagement?.DeviceUsersChild?.edit,
            [paths.dashboard.users.bulkedit]: permissions?.DeviceManagement?.DeviceUsersChild?.edit,
            [paths.dashboard.users.create]: permissions?.DeviceManagement?.DeviceUsersChild?.write,

            // geo fencing
            [paths.dashboard.geofencing_v2.root]:
                permissions?.LocationManagement?.GeoFencingChild?.read,
            [paths.dashboard.geofencing_v2.cards]:
                permissions?.LocationManagement?.GeoFencingChild?.edit,
            [paths.dashboard.geofencing_v2.create]:
                permissions?.LocationManagement?.GeoFencingChild?.write,

            // live track

            // site admin users
            [paths.dashboard.user.root]: permissions?.SiteAdministration?.UsersChild?.read,
            [paths.dashboard.user.new]: permissions?.SiteAdministration?.UsersChild?.write,
            [paths.dashboard.user.list]: permissions?.SiteAdministration?.UsersChild?.read,
            [paths.dashboard.user.cards]: permissions?.SiteAdministration?.UsersChild?.edit,
            [paths.dashboard.user.profile]: permissions?.SiteAdministration?.UsersChild?.read,
            [paths.dashboard.user.account]: permissions?.SiteAdministration?.UsersChild?.edit,
            [paths.dashboard.user.edit]: permissions?.SiteAdministration?.UsersChild?.edit,
            [paths.dashboard.user.demo]: permissions?.SiteAdministration?.UsersChild?.edit,

            // site admin roles
            [paths.dashboard.roles.root]: permissions?.SiteAdministration?.RolesChild?.read,
            [paths.dashboard.roles.list]: permissions?.SiteAdministration?.RolesChild?.read,
            [paths.dashboard.roles.edit]: permissions?.SiteAdministration?.RolesChild?.edit,
            [paths.dashboard.roles.create]: permissions?.SiteAdministration?.RolesChild?.write,

            // site admin permissions
            [paths.dashboard.permissions.root]:
                permissions?.SiteAdministration?.PermissionsChild?.read,
            [paths.dashboard.permissions.list]:
                permissions?.SiteAdministration?.PermissionsChild?.read,
            [paths.dashboard.permissions.edit]:
                permissions?.SiteAdministration?.PermissionsChild?.edit,
            [paths.dashboard.permissions.create]:
                permissions?.SiteAdministration?.PermissionsChild?.write,

            // site admin organizarion
            [paths.dashboard.organisation.root]: permissions?.UEMSettings?.OrganisationChild?.read,
            [paths.dashboard.organisation.edit]: permissions?.UEMSettings?.OrganisationChild?.edit,

            // site admin enterprise
            [paths.dashboard.enterprises.root]:
                permissions?.UEMSettings?.EnterprisesDivisionsChild?.read,
            [paths.dashboard.enterprises.list]:
                permissions?.UEMSettings?.EnterprisesDivisionsChild?.read,
            [paths.dashboard.enterprises.edit]:
                permissions?.UEMSettings?.EnterprisesDivisionsChild?.edit,
            [paths.dashboard.enterprises.create]:
                permissions?.UEMSettings?.EnterprisesDivisionsChild?.write,
            [paths.dashboard.enterprises.details]:
                permissions?.UEMSettings?.EnterprisesDivisionsChild?.read,

            // uem settings
            [paths.dashboard.setting.root]: permissions?.UEMSettings.SettingsChild?.read,
            [paths.dashboard.setting.edit]: permissions?.UEMSettings.SettingsChild?.edit,

            // tools
            // [paths.dashboard.tools.root]: permissions?.Tools?.ToolsChild?.read,
            // [paths.dashboard.tools.notification]: permissions?.Tools?.ToolsChild?.write,

            // report
            // [paths.dashboard.reports.root]: permissions?.Reports?.ReportChild?.read,

            // teamxcell administrative-boundary
            [paths.dashboard.teamxcelgeofence.root]:
                permissions?.UEMSettings?.AdministrativeBoundaryChild?.read,
            [paths.dashboard.teamxcelgeofence.list]:
                permissions?.UEMSettings?.AdministrativeBoundaryChild?.read,
            [paths.dashboard.teamxcelgeofence.create]:
                permissions?.UEMSettings?.AdministrativeBoundaryChild?.write,

            // teamxcell Reports
            [paths.dashboard.teamxcelreports.root]:
                permissions?.TeamXCEL?.TeamxcelReportsChild?.read,
            [paths.dashboard.teamxcelreports.projectreport]:
                permissions?.TeamXCEL?.TeamxcelReportsChild?.read,
            [paths.dashboard.teamxcelreports.absentreport]:
                permissions?.TeamXCEL?.TeamxcelReportsChild?.read,
            [paths.dashboard.teamxcelreports.employeereport]:
                permissions?.TeamXCEL?.TeamxcelReportsChild?.read,
            [paths.dashboard.teamxcelreports.teamreport]:
                permissions?.TeamXCEL?.TeamxcelReportsChild?.read,
            [paths.dashboard.teamxcelreports.clockinoutreport]:
                permissions?.TeamXCEL?.TeamxcelReportsChild?.read,

            // teamxcell Employee
            [paths.dashboard.employee.root]: permissions?.TeamXCEL?.EmployeeChild?.read,
            [paths.dashboard.employee.list]: permissions?.TeamXCEL?.EmployeeChild?.read,
            [paths.dashboard.employee.edit]: permissions?.TeamXCEL?.EmployeeChild?.edit,
            [paths.dashboard.employee.create]: permissions?.TeamXCEL?.EmployeeChild?.write,

            // teamxcell Enteprise Project
            [paths.dashboard.enterpriseproject.root]:
                permissions?.TeamXCEL?.EnterpriseProjectChild?.read,
            [paths.dashboard.enterpriseproject.list]:
                permissions?.TeamXCEL?.EnterpriseProjectChild?.read,
            [paths.dashboard.enterpriseproject.edit]:
                permissions?.TeamXCEL?.EnterpriseProjectChild?.edit,
            [paths.dashboard.enterpriseproject.create]:
                permissions?.TeamXCEL?.EnterpriseProjectChild?.write,

            // teamxcell Office
            [paths.dashboard.office.root]: permissions?.TeamXCEL?.OfficeChild?.read,
            [paths.dashboard.office.list]: permissions?.TeamXCEL?.OfficeChild?.read,
            [paths.dashboard.office.edit]: permissions?.TeamXCEL?.OfficeChild?.edit,
            [paths.dashboard.office.create]: permissions?.TeamXCEL?.OfficeChild?.write,

            // teamxcell Project Assignment
            [paths.dashboard.projectteams.root]: permissions?.TeamXCEL?.ProjectTeamsChild?.read,
            [paths.dashboard.projectteams.list]: permissions?.TeamXCEL?.ProjectTeamsChild?.read,
            [paths.dashboard.projectteams.edit]: permissions?.TeamXCEL?.ProjectTeamsChild?.edit,
            [paths.dashboard.projectteams.create]: permissions?.TeamXCEL?.ProjectTeamsChild?.write,

            // teamxcell Dashboard
            [paths.dashboard.teamxceldashboard.root]:
                permissions?.TeamXCEL?.TeamxcelDashboardChild?.read,
            // [paths.dashboard.dashboardandreports.reports]:
            //     permissions?.TeamXCEL?.TeamxcelDashboardChild?.read,
            // [paths.dashboard.dashboardandreports.]:
            //     permissions?.TeamXCEL?.DashboardandReportsChild?.edit,
            // [paths.dashboard.projectemployeeregistration.create]:
            //     permissions?.TeamXCEL?.DashboardandReportsChild?.write,
        }),
        [permissions]
    );

    useEffect(() => {
        const currentPath = window.location.pathname;
        const pathFromDashboard = currentPath.slice(currentPath.indexOf('/dashboard'));
        const requiredPermission = pathsWithPermissions?.[pathFromDashboard];
        console.log('re', requiredPermission);
        if (requiredPermission === false || requiredPermission === null) {
            router.replace(paths.page403);
        }
    }, [permissions, router, pathsWithPermissions]);

    console.log('paths', window.location.href);

    return <>{children}</>;
}

export default PermissionGuard;

PermissionGuard.propTypes = {
    children: PropTypes.node,
};
