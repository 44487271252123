import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
import Label from 'src/components/label';
// components
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/hooks';
// ----------------------------------------------------------------------

const EDIT = 'edit';
const WRITE = 'write';
const READ = 'read';
const DELETE = 'delete';

export function useNavData() {
    const { t } = useLocales();
    const { permissions } = useAuthContext();
    console.log('permissions', permissions);

    const data = useMemo(() => {
        const PermissionsMapper = (childern, parent) =>
            childern?.map((item) => ({ ...item, permission: parent[item.permissionType] }));
        return [
            // Device Management
            // ----------------------------------------------------------------------
            {
                // subheader: t('Device Management'),
                icon: 'solar:devices-bold-duotone',
                items: PermissionsMapper(
                    [
                        {
                            title: t('Device Management'),
                            permissionType: 'DeviceManagement',
                            path: 'dashboard/device-management',
                            icon: <Iconify icon="solar:devices-bold-duotone" />,
                            children: PermissionsMapper(
                                [
                                    // {
                                    //     title: t('Enterprises and Groups'),
                                    //     path: paths.dashboard.enterprisesandgroups.root,
                                    //     children: [
                                    //         { title: t('Action on Groups'), path: paths.dashboard.enterprisesandgroups.root },
                                    //     ],
                                    // },

                                    // {
                                    //     title: t('Certificates'),
                                    //     path: paths.dashboard.certificates.root,
                                    //     children: [
                                    //         {
                                    //             title: t('Details'),
                                    //             path: paths.dashboard.certificates.root,
                                    //         },
                                    //         {
                                    //             title: t('List'),
                                    //             path: paths.dashboard.certificates.list,
                                    //         },
                                    //         {
                                    //             title: t('Create'),
                                    //             path: paths.dashboard.certificates.create,
                                    //         },
                                    //         {
                                    //             title: t('Edit'),
                                    //             path: paths.dashboard.certificates.edit,
                                    //         },
                                    //         {
                                    //             title: t('CA Server'),
                                    //             path: paths.dashboard.certificates.caserver,
                                    //         },
                                    //         {
                                    //             title: t('Templates'),
                                    //             path: paths.dashboard.certificates.templates,
                                    //         },
                                    //     ],
                                    // },

                                    // {
                                    //     title: t('Device Alerts'),
                                    //     permissionType: 'DeviceAlerts',
                                    //     path: paths.dashboard.devicealerts.root,
                                    //     children: PermissionsMapper(
                                    //         [
                                    //             // { title: t('Details'), path: paths.dashboard.devicealerts.root },
                                    //             {
                                    //                 title: t('Edit'),
                                    //                 path: paths.dashboard.devicealerts.edit,
                                    //                 permissionType: EDIT,
                                    //             },
                                    //             {
                                    //                 title: t('Create'),
                                    //                 path: paths.dashboard.devicealerts.create,
                                    //                 permissionType: WRITE,
                                    //             },
                                    //         ],
                                    //         permissions?.DeviceManagement?.DeviceAlertsChild
                                    //     ),
                                    // },

                                    // {
                                    //     title: t('OS Updates'),
                                    //     path: paths.dashboard.osupdates.root,
                                    //     permissionType: 'OSUpdates',
                                    //     children: PermissionsMapper(
                                    //         [
                                    //             {
                                    //                 title: t('Details'),
                                    //                 path: paths.dashboard.osupdates.root,
                                    //                 permissionType: READ,
                                    //             },
                                    //             {
                                    //                 title: t('List'),
                                    //                 path: paths.dashboard.osupdates.list,
                                    //                 permissionType: READ,
                                    //             },
                                    //             {
                                    //                 title: t('Create'),
                                    //                 path: paths.dashboard.osupdates.create,
                                    //                 permissionType: WRITE,
                                    //             },
                                    //             {
                                    //                 title: t('Edit'),
                                    //                 path: paths.dashboard.osupdates.edit,
                                    //                 permissionType: EDIT,
                                    //             },
                                    //         ],
                                    //         permissions?.DeviceManagement?.OSUpdatesChild
                                    //     ),
                                    // },

                                    {
                                        title: t('Enrollment'),
                                        path: paths.dashboard.device.root,
                                        permissionType: 'Enrollment',
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.device.root,
                                                    permissionType: READ,
                                                },
                                                // {
                                                //     title: t('Token Enroll Device'),
                                                //     path: paths.dashboard.device
                                                //         .enrolldeviceandroid,
                                                //     permissionType: WRITE,
                                                // },
                                                {
                                                    title: t('Enroll Device'),
                                                    path: paths.dashboard.device
                                                        .enrolldeviceandroid2,
                                                    permissionType: WRITE,
                                                },
                                                {
                                                    title: t('Token Cards'),
                                                    path: paths.dashboard.device.tokensTab,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('Token List'),
                                                    path: paths.dashboard.device.tokenList,
                                                    permissionType: READ,
                                                },
                                            ],
                                            permissions?.DeviceManagement?.EnrollmentChild
                                        ),
                                    },

                                    {
                                        title: t('Device Users'),
                                        permissionType: 'DeviceUsers',
                                        path: paths.dashboard.users.root,
                                        children: PermissionsMapper(
                                            [
                                                // { title: t('Details'), path: paths.dashboard.users.root },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.users.list,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.users.create,
                                                    permissionType: WRITE,
                                                },
                                                {
                                                    title: t('Cards'),
                                                    path: paths.dashboard.users.cards,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('Bulk Edit'),
                                                    path: paths.dashboard.users.bulkedit,
                                                    permissionType: EDIT,
                                                },
                                            ],
                                            permissions?.DeviceManagement?.DeviceUsersChild
                                        ),
                                    },

                                    {
                                        title: t('Groups'),
                                        permissionType: 'Groups',
                                        path: paths.dashboard.groups.root,
                                        children: PermissionsMapper(
                                            [
                                                // {
                                                //   title: t('Details'),
                                                //   path: paths.dashboard.groups.root,
                                                //   permissionType: READ,
                                                // },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.groups.list,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.groups.create,
                                                    permissionType: WRITE,
                                                },
                                                // {
                                                //   title: t('Edit'),
                                                //   path: paths.dashboard.groups.edit,
                                                //   permissionType: EDIT,
                                                // },
                                            ],
                                            permissions?.DeviceManagement?.GroupsChild
                                        ),
                                    },

                                    {
                                        title: t('Inventory'),
                                        path: paths.dashboard.devices.root,
                                        permissionType: 'Inventory',
                                        children: PermissionsMapper(
                                            [
                                                // {
                                                //     title: t('Add Device'),
                                                //     path: paths.dashboard.devices.create,
                                                //     permissionType: WRITE,
                                                // },
                                                {
                                                    title: t('Add Device'),
                                                    path: paths.dashboard.devices.newcreate,
                                                    permissionType: WRITE,
                                                },
                                                // {
                                                //   title: t('Details'),
                                                //   path: paths.dashboard.devices.root,
                                                //   permissionType: READ,
                                                // },
                                                // { title: t('Details1'), path: paths.dashboard.devices.detail },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.devices.list,
                                                    permissionType: READ,
                                                },
                                                // {
                                                //   title: t('Edit'),
                                                //   path: paths.dashboard.devices.edit,
                                                //   permissionType: EDIT,
                                                // },
                                                {
                                                    title: t('Bulk Entry'),
                                                    path: paths.dashboard.devices.bulkedit,
                                                    permissionType: EDIT,
                                                },
                                            ],
                                            permissions?.DeviceManagement?.InventoryChild
                                        ),
                                    },
                                    {
                                        title: t('Device Scan'),
                                        permissionType: 'ScheduleDeviceScan',
                                        path: paths.dashboard.scheduledevicescan.root,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('Create Schedule'),
                                                    path: paths.dashboard.scheduledevicescan.root,
                                                    permissionType: WRITE,
                                                },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.scheduledevicescan
                                                        .scannow,
                                                    permissionType: WRITE,
                                                },
                                            ],
                                            permissions?.DeviceManagement?.EnrollmentChild
                                        ),
                                    },
                                ],
                                permissions?.DeviceManagement
                            ),
                        },
                    ],
                    permissions?.DeviceManagement
                ),
            },

            // Content Manangement
            // ----------------------------------------------------------------------
            {
                // subheader: t('Content Manangement'),
                icon: 'solar:shield-network-bold-duotone',
                items: PermissionsMapper(
                    [
                        {
                            title: t('Device Security'),
                            path: 'dashboard/device-security',
                            icon: <Iconify icon="solar:shield-network-bold-duotone" />,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Profiles'),
                                        path: paths.dashboard.profiles.root,
                                        permissionType: 'Profiles',
                                        children: PermissionsMapper(
                                            [
                                                // { title: t('Details'), path: paths.dashboard.profiles.root },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.profiles.root,
                                                    permissionType: READ,
                                                },
                                                // {
                                                //   title: t('Details'),
                                                //   path: paths.dashboard.profiles.detail,
                                                //   permissionType: READ,
                                                // },
                                                // {
                                                //   title: t('Create'),
                                                //   path: paths.dashboard.profiles.create,
                                                //   permissionType: WRITE,
                                                // },
                                                // {
                                                //   title: t('Edit'),
                                                //   path: paths.dashboard.profiles.edit,
                                                //   permissionType: EDIT,
                                                // },
                                            ],
                                            permissions?.DeviceSecurity?.ProfilesChild
                                        ),
                                    },

                                    {
                                        title: t('Policies'),
                                        path: paths.dashboard.policies.root,
                                        permissionType: 'Profiles',
                                        children: PermissionsMapper(
                                            [
                                                // { title: t('Details'), path: paths.dashboard.profiles.root },
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.policies.create,
                                                    permissionType: WRITE,
                                                },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.policies.list,
                                                    permissionType: READ,
                                                },
                                                // {
                                                //   title: t('Details'),
                                                //   path: paths.dashboard.profiles.detail,
                                                //   permissionType: READ,
                                                // },
                                                // {
                                                //   title: t('Create'),
                                                //   path: paths.dashboard.profiles.create,
                                                //   permissionType: WRITE,
                                                // },
                                                // {
                                                //   title: t('Edit'),
                                                //   path: paths.dashboard.profiles.edit,
                                                //   permissionType: EDIT,
                                                // },
                                            ],
                                            permissions?.DeviceSecurity?.ProfilesChild
                                        ),
                                    },
                                ],
                                permissions?.DeviceSecurity
                            ),
                        },
                    ],
                    permissions?.DeviceSecurity
                ),
            },

            // Application Manangement
            // ----------------------------------------------------------------------
            {
                // subheader: t('Application Manangement'),
                icon: 'solar:laptop-bold-duotone',
                items: PermissionsMapper(
                    [
                        {
                            title: t('Application Manangement'),
                            permissionType: 'ApplicationManagement',
                            path: 'dashboard/application-management',
                            icon: <Iconify icon="solar:laptop-bold-duotone" />,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('App Catelog'),
                                        path: paths.dashboard.apprepository.root,
                                        permissionType: 'AppCatelog',
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('App Cards'),
                                                    path: paths.dashboard.apprepository.root,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('App List'),
                                                    path: paths.dashboard.apprepository.applist,
                                                    permissionType: READ,
                                                },
                                                // {
                                                //     title: t('Organise Apps'),
                                                //     path: paths.dashboard.apprepository
                                                //         .organizeapps,
                                                //     permissionType: READ,
                                                // },
                                            ],
                                            permissions?.ApplicationManagement?.AppCatelogChild
                                        ),
                                    },
                                    {
                                        title: t('App Stores'),
                                        path: paths.dashboard.apprepository.root2,
                                        permissionType: 'AppStores',
                                        children: PermissionsMapper(
                                            [
                                                // {
                                                //     title: t('Old App Store'),
                                                //     path: paths.dashboard.apprepository.createtest,
                                                //     permissionType: READ,
                                                // },

                                                {
                                                    title: t('Google'),
                                                    path: paths.dashboard.apprepository.root3,
                                                    permissionType: 'AppStores',
                                                    children: [
                                                        {
                                                            title: t('Public Apps'),
                                                            path: paths.dashboard.apprepository
                                                                .publicapps,
                                                            permissionType: WRITE,
                                                        },
                                                        {
                                                            title: t('Private Apps'),
                                                            path: paths.dashboard.apprepository
                                                                .privateapps,
                                                            permissionType: WRITE,
                                                        },
                                                        {
                                                            title: t('Web Apps'),
                                                            path: paths.dashboard.apprepository
                                                                .webapps,
                                                            permissionType: WRITE,
                                                        },
                                                        {
                                                            title: t('Organise Apps'),
                                                            path: paths.dashboard.apprepository
                                                                .organizeapps,
                                                            permissionType: WRITE,
                                                        },
                                                    ],
                                                },
                                                {
                                                    title: t('Org Store'),
                                                    path: paths.dashboard.apprepository
                                                        .orgprivatestore,
                                                    permissionType: WRITE,
                                                },
                                            ],
                                            permissions?.ApplicationManagement?.AppStoresChild
                                        ),
                                    },

                                    // {
                                    //   title: t('App Update Policy'),
                                    //   permissionType: 'AppUpdatePolicy',
                                    //   path: paths.dashboard.appupdatepolicy.root,
                                    //   children: PermissionsMapper(
                                    //     [
                                    //       // { title: t('Details'), path: paths.dashboard.appupdatepolicy.root },
                                    //       // { title: t('Edit'), path: paths.dashboard.appupdatepolicy.edit },
                                    //       {
                                    //         title: t('list'),
                                    //         path: paths.dashboard.appupdatepolicy.list,
                                    //         permissionType: READ,
                                    //       },
                                    //     ],
                                    //     permissions?.ApplicationManagement?.AppUpdatePolicyChild
                                    //   ),
                                    // },
                                    // {
                                    //   title: t('App Catelog'),
                                    //   path: paths.dashboard.appupdatepolicy.root,
                                    //   children: [
                                    //     { title: t('Details'), path: paths.dashboard.appupdatepolicy.root },
                                    //     { title: t('List'), path: paths.dashboard.appupdatepolicy.root },
                                    //     { title: t('Create'), path: paths.dashboard.appupdatepolicy.root },
                                    //   ],
                                    // },
                                    // {
                                    //   title: t('App Distribution'),
                                    //   path: paths.dashboard.appupdatepolicy.root,
                                    //   children: [
                                    //     { title: t('Details'), path: paths.dashboard.appupdatepolicy.root },
                                    //     { title: t('List'), path: paths.dashboard.appupdatepolicy.root },
                                    //     { title: t('Create'), path: paths.dashboard.appupdatepolicy.root },
                                    //   ],
                                    // },

                                    // {
                                    //   title: t('App Stores'),
                                    //   path: paths.dashboard.appupdatepolicy.root,
                                    //   children: [
                                    //     { title: t('Public Google Play Store'), path: paths.dashboard.appupdatepolicy.root },
                                    //     { title: t('Managed Google Play Store'), path: paths.dashboard.appupdatepolicy.root },
                                    //     { title: t('Customised Google Play Store'), path: paths.dashboard.appupdatepolicy.root },
                                    //     { title: t('CruxUEM Private Store'), path: paths.dashboard.appupdatepolicy.root },
                                    //   ],
                                    // },
                                ],
                                permissions?.ApplicationManagement
                            ),
                        },
                    ],
                    permissions?.ApplicationManagement
                ),
            },

            // Content Manangement
            // ----------------------------------------------------------------------
            // {
            //   // subheader: t('Content Manangement'),
            //   icon: 'solar:shield-network-bold-duotone',
            //   items: [
            //     {
            //       title: t('Content Management'),
            //       path: 'dashboard/content-management',
            //       icon: <Iconify icon="solar:shield-network-bold-duotone" />,
            //       children: [
            //         {
            //           title: t('Content'),
            //           path: paths.dashboard.content.root,
            //           children: [
            //             { title: t('Details'), path: paths.dashboard.content.root },
            //             { title: t('List'), path: paths.dashboard.content.list },
            //             { title: t('Create'), path: paths.dashboard.content.create },
            //             { title: t('Edit'), path: paths.dashboard.content.edit },
            //           ],
            //         },
            //       ],
            //     },

            //   ],
            // },

            // Enrollement
            // ----------------------------------------------------------------------
            // {
            //   // subheader: t('Enrollment'),
            //   icon: 'solar:document-add-bold-duotone',
            //   items: PermissionsMapper(
            //     [
            //       {
            //         title: t('Enrollment'),
            //         path: 'dashboard/enrollments',
            //         permissionType: 'Enrollment',
            //         icon: <Iconify icon="solar:document-add-bold-duotone" />,
            //         children: PermissionsMapper(
            //           [
            //             {
            //               title: t('Devices'),
            //               path: paths.dashboard.device.root,
            //               permissionType: 'Devices',
            //               children: PermissionsMapper(
            //                 [
            //                   {
            //                     title: t('List Enrolled Devices'),
            //                     path: paths.dashboard.device.root,
            //                     permissionType: READ,
            //                   },
            //                   {
            //                     title: t('Enroll Device'),
            //                     path: paths.dashboard.device.enrolldeviceandroid,
            //                     permissionType: WRITE,
            //                   },
            //                 ],
            //                 permissions?.Enrollment?.DevicesChild
            //               ),
            //             },

            //             {
            //               title: t('Groups'),
            //               permissionType: 'Groups',
            //               path: paths.dashboard.groups.root,
            //               children: PermissionsMapper(
            //                 [
            //                   // {
            //                   //   title: t('Details'),
            //                   //   path: paths.dashboard.groups.root,
            //                   //   permissionType: READ,
            //                   // },
            //                   {
            //                     title: t('List'),
            //                     path: paths.dashboard.groups.list,
            //                     permissionType: READ,
            //                   },
            //                   {
            //                     title: t('Create'),
            //                     path: paths.dashboard.groups.create,
            //                     permissionType: WRITE,
            //                   },
            //                   // {
            //                   //   title: t('Edit'),
            //                   //   path: paths.dashboard.groups.edit,
            //                   //   permissionType: EDIT,
            //                   // },
            //                 ],
            //                 permissions?.Enrollment?.GroupsChild
            //               ),
            //             },

            //             // {
            //             //   title: t('Device Users'),
            //             //   permissionType: 'DeviceUsers',
            //             //   path: paths.dashboard.users.root,
            //             //   children: PermissionsMapper(
            //             //     [
            //             //       // { title: t('Details'), path: paths.dashboard.users.root },
            //             //       {
            //             //         title: t('List'),
            //             //         path: paths.dashboard.users.list,
            //             //         permissionType: READ,
            //             //       },
            //             //       {
            //             //         title: t('Create'),
            //             //         path: paths.dashboard.users.create,
            //             //         permissionType: WRITE,
            //             //       },
            //             //       // { title: t('Edit'), path: paths.dashboard.users.edit },
            //             //       // {
            //             //       //   title: t('Bulk Edit'),
            //             //       //   path: paths.dashboard.users.bulkedit,
            //             //       //   permissionType: EDIT,
            //             //       // },
            //             //     ],
            //             //     permissions?.Enrollment?.DeviceUsersChild
            //             //   ),
            //             // },

            //             // {
            //             //   title: t('Self Enrollment'),
            //             //   path: paths.dashboard.selfenrollment.root,
            //             //   children: [
            //             //     { title: t('Details'), path: paths.dashboard.selfenrollment.root },
            //             //     { title: t('Edit'), path: paths.dashboard.selfenrollment.edit },
            //             //   ],
            //             // },

            //             // {
            //             //   title: t('Directory Services'),
            //             //   path: paths.dashboard.directoryservices.root,
            //             //   children: [
            //             //     { title: t('Details'), path: paths.dashboard.directoryservices.root },
            //             //     { title: t('Edit'), path: paths.dashboard.directoryservices.edit },
            //             //   ],
            //             // },

            //             // {
            //             //   title: t('Andriod Devices'),
            //             //   path: paths.dashboard.androiddevices.root,
            //             //   children: [
            //             //     {
            //             //       title: t('EMM Token Enrollment'),
            //             //       path: paths.dashboard.androiddevices.root,
            //             //     },
            //             //     {
            //             //       title: t('Zero Touch Enrollment'),
            //             //       path: paths.dashboard.androiddevices.zerotouchenrollment,
            //             //     },
            //             //     {
            //             //       title: t('NFC Enrollment'),
            //             //       path: paths.dashboard.androiddevices.nfcenrollment,
            //             //     },
            //             //     {
            //             //       title: t('Knox Mobile Enrollment'),
            //             //       path: paths.dashboard.androiddevices.knoxmobileenrollment,
            //             //     },
            //             //   ],
            //             // },

            //             // {
            //             //   title: t('Crux UEM App'),
            //             //   path: paths.dashboard.cruxuemapp.root,
            //             //   children: [
            //             //     {
            //             //       title: t('QR Code Link To App'),
            //             //       path: paths.dashboard.cruxuemapp.root,
            //             //     },
            //             //   ],
            //             // },
            //           ],
            //           permissions?.Enrollment
            //         ),
            //       },
            //     ],
            //     permissions?.Enrollment
            //   ),
            // },

            // Location Manangement
            // ----------------------------------------------------------------------
            {
                // subheader: t('Location Manangement'),,
                icon: 'solar:point-on-map-bold-duotone',
                items: PermissionsMapper(
                    [
                        {
                            title: t('Location Management'),
                            permissionType: 'LocationManagement',
                            path: 'dashboard/location-management',
                            icon: <Iconify icon="solar:point-on-map-bold-duotone" />,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Device Tracking'),
                                        permissionType: 'GeoFencing',
                                        path: paths.dashboard.livetracking.root,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('Location History'),
                                                    path: paths.dashboard.livetracking
                                                        .locationHistory,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('Live Tracking'),
                                                    path: paths.dashboard.livetracking.liveTracking,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('Bread Crumbs'),
                                                    path: paths.dashboard.livetracking.routeMaps,
                                                    permissionType: READ,
                                                },
                                            ],
                                            permissions?.LocationManagement?.GeoFencingChild
                                        ),
                                    },

                                    {
                                        title: t('Geo Fencing'),
                                        permissionType: 'GeoFencing',
                                        path: paths.dashboard.geofencing_v2.root,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('Create Fence'),
                                                    path: paths.dashboard.geofencing_v2.create,
                                                    permissionType: WRITE,
                                                },
                                                {
                                                    title: t('Available Fences'),
                                                    path: paths.dashboard.geofencing_v2.cards,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('Logs'),
                                                    path: paths.dashboard.geofencing_v2.logs,
                                                    permissionType: READ,
                                                },
                                            ],
                                            permissions?.LocationManagement?.GeoFencingChild
                                        ),
                                    },
                                    // {
                                    //     title: t('Tracking Settings'),
                                    //     permissionType: 'GeoFencing',
                                    //     path: paths.dashboard.geofencing_v2.root,
                                    //     children: PermissionsMapper(
                                    //         [
                                    //             {
                                    //                 title: t('Battery Level'),
                                    //                 path: '',
                                    //                 permissionType: WRITE,
                                    //                 disabled:true
                                    //             },
                                    //             {
                                    //                 title: t('Location History'),
                                    //                 path: '',
                                    //                 permissionType: WRITE,
                                    //                 disabled:true
                                    //             },
                                    //             {
                                    //                 title: t('Bread Crumbs'),
                                    //                 path: '',
                                    //                 permissionType: WRITE,
                                    //                 disabled:true
                                    //             },
                                    //         ],
                                    //         permissions?.LocationManagement?.GeoFencingChild
                                    //     ),
                                    // }
                                ],
                                permissions?.LocationManagement
                            ),
                        },
                    ],
                    permissions?.LocationManagement
                ),
            },
            // CONTENT MANAGEMENT
            // {
            //     icon:<Iconify icon="solar:point-on-map-bold-duotone" />,
            //     items:[
            //         title:t('Content Management'),
            //         path:paths.dashboard.content,
            //         children:[
            //             {
            //                 title:t('File Manager'),
            //                 path:paths.dashboard.content,
            //                 icon: <Iconify icon="solar:user-id-bold-duotone" />,
            //                 children:[
            //                     {
            //                         title:t('File List'),
            //                         path:paths.dashboard.content,
            //                         icon: <Iconify icon="solar:user-id-bold-duotone" />
            //                     }
            //                 ]

            //             }
            //         ]
            //     ]

            // },
            {
                icon: 'ic:baseline-file-copy',
                items: PermissionsMapper(
                    [
                        {
                            title: t('Content Management'),
                            permissionType: 'LocationManagement',
                            path: paths.dashboard.content.root,
                            icon: <Iconify icon="ic:baseline-file-copy" />,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('File Manager'),
                                        permissionType: 'GeoFencing',
                                        path: paths.dashboard.content.root,
                                        // children: PermissionsMapper(
                                        //     [
                                        //         {
                                        //             title: t('File List'),
                                        //             path: paths.dashboard.content.root,
                                        //             permissionType: READ,
                                        //         }

                                        //     ],
                                        //     permissions?.LocationManagement?.GeoFencingChild
                                        // ),
                                    },
                                ],
                                permissions?.LocationManagement
                            ),
                        },
                    ],
                    permissions?.LocationManagement
                ),
            },

            // User Management
            // ----------------------------------------------------------------------
            {
                // subheader: t('User Management'),
                items: PermissionsMapper(
                    [
                        {
                            title: t('Site Administration'),
                            path: 'dashboard/site-administration',
                            permissionType: 'SiteAdministration',
                            icon: <Iconify icon="solar:user-id-bold-duotone" />,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Users'),
                                        path: paths.dashboard.user.root,
                                        permissionType: 'Users',
                                        children: PermissionsMapper(
                                            [
                                                // {
                                                //     title: t('Details'),
                                                //     path: paths.dashboard.user.root,
                                                //     permissionType: READ,
                                                // },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.user.list,
                                                    permissionType: EDIT,
                                                },
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.user.new,
                                                    permissionType: WRITE,
                                                },
                                                // { title: t('Edit'), path: paths.dashboard.user.edit, permissionType: EDIT },
                                                {
                                                    title: t('User Cards'),
                                                    path: paths.dashboard.user.cards,
                                                    permissionType: EDIT,
                                                },
                                                // {
                                                //   title: t('User Accounts'),
                                                //   path: paths.dashboard.user.account,
                                                //   permissionType: EDIT,
                                                // },
                                            ],
                                            permissions?.SiteAdministration?.UsersChild
                                        ),
                                    },

                                    {
                                        title: t('Roles'),
                                        path: paths.dashboard.roles.root,
                                        permissionType: 'Roles',
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('Role Cards'),
                                                    path: paths.dashboard.roles.root,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.roles.list,
                                                    permissionType: EDIT,
                                                },
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.roles.create,
                                                    permissionType: WRITE,
                                                },
                                                // {
                                                //   title: t('Edit'),
                                                //   path: paths.dashboard.roles.edit,
                                                //   permissionType: EDIT,
                                                // },
                                            ],
                                            permissions?.SiteAdministration?.RolesChild
                                        ),
                                    },

                                    {
                                        title: t('Permissions'),
                                        permissionType: 'Permissions',
                                        path: paths.dashboard.permissions.root,
                                        children: PermissionsMapper(
                                            [
                                                // { title: t('Details'), path: paths.dashboard.permissions.root },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.permissions.list,
                                                    permissionType: READ,
                                                },
                                                // { title: t('Create'), path: paths.dashboard.permissions.create },
                                                // { title: t('Edit'), path: paths.dashboard.permissions.edit },
                                            ],
                                            permissions?.SiteAdministration?.PermissionsChild
                                        ),
                                    },

                                    // {
                                    //   title: t('Organisation Hierarchy'),
                                    //   path: paths.dashboard.organisationhierarchy.root,
                                    //   children: [
                                    //     { title: t('Details'), path: paths.dashboard.organisationhierarchy.root },
                                    //     { title: t('List'), path: paths.dashboard.organisationhierarchy.list },
                                    //     { title: t('Create'), path: paths.dashboard.organisationhierarchy.create },
                                    //     { title: t('Edit'), path: paths.dashboard.organisationhierarchy.edit },
                                    //   ],
                                    // },
                                ],
                                permissions?.SiteAdministration
                            ),
                        },
                    ],
                    permissions?.SiteAdministration
                ),
            },

            // UEM Settings
            // ----------------------------------------------------------------------
            {
                // subheader: t('UEM Settings'),
                icon: 'solar:settings-bold-duotone',
                items: PermissionsMapper(
                    [
                        {
                            title: t('UEM Settings'),
                            permissionType: 'UEMSettings',
                            path: 'dashboard/uem-setting',
                            icon: <Iconify icon="solar:settings-bold-duotone" />,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Organisation'),
                                        permissionType: 'Organisation',
                                        path: paths.dashboard.organisation.root,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('Details'),
                                                    path: paths.dashboard.organisation.root,
                                                    permissionType: READ,
                                                },
                                                // {
                                                //   title: t('Edit'),
                                                //   path: paths.dashboard.organisation.edit,
                                                //   permissionType: EDIT,
                                                // },
                                            ],
                                            permissions?.UEMSettings?.OrganisationChild
                                        ),
                                    },

                                    {
                                        title: t('Enterprises'),
                                        permissionType: 'EnterprisesDivisions',
                                        path: paths.dashboard.enterprises.root,
                                        children: PermissionsMapper(
                                            [
                                                // {
                                                //     title: t('Details'),
                                                //     path: paths.dashboard.enterprises.root,
                                                //     permissionType: READ,
                                                // },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.enterprises.list,
                                                    permissionType: EDIT,
                                                },
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.enterprises.create,
                                                    permissionType: WRITE,
                                                },
                                                // {
                                                //     title: t('Edit'),
                                                //     path: paths.dashboard.enterprises.edit,
                                                //     permissionType: EDIT,zz
                                                // },
                                            ],
                                            permissions?.UEMSettings?.EnterprisesDivisionsChild
                                        ),
                                    },
                                    {
                                        title: t('Settings'),
                                        permissionType: 'Settings',
                                        path: paths.dashboard.setting.root,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('Details'),
                                                    path: paths.dashboard.setting.root,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('Edit'),
                                                    path: paths.dashboard.setting.edit,
                                                    permissionType: EDIT,
                                                },
                                            ],
                                            permissions?.UEMSettings?.SettingsChild
                                        ),
                                    },

                                    // {
                                    //     title: t('Administrative Boundary'),
                                    //     permissionType: 'AdministrativeBoundary',
                                    //     path: paths.dashboard.administrativeboundary.root,
                                    //     children: PermissionsMapper(
                                    //         [
                                    //             {
                                    //                 title: t('Details'),
                                    //                 path: paths.dashboard.administrativeboundary
                                    //                     .root,
                                    //                 permissionType: READ,
                                    //             },
                                    //             {
                                    //                 title: t('Create'),
                                    //                 path: paths.dashboard.administrativeboundary
                                    //                     .create,
                                    //                 permissionType: WRITE,
                                    //             },
                                    //             {
                                    //                 title: t('Edit'),
                                    //                 path: paths.dashboard.administrativeboundary
                                    //                     .edit,
                                    //                 permissionType: EDIT,
                                    //             },
                                    //             {
                                    //                 title: t('List'),
                                    //                 path: paths.dashboard.administrativeboundary
                                    //                     .list,
                                    //                 permissionType: READ,
                                    //             },
                                    //         ],
                                    //         permissions?.UEMSettings?.AdministrativeBoundaryChild
                                    //     ),
                                    // },

                                    // {
                                    //   title: t('Terms of Use Doc'),
                                    //   path: paths.dashboard.termsofusedoc.root,
                                    //   children: [
                                    //     { title: t('Details'), path: paths.dashboard.termsofusedoc.root },
                                    //     { title: t('List'), path: paths.dashboard.termsofusedoc.list },
                                    //     { title: t('Create'), path: paths.dashboard.termsofusedoc.create },
                                    //     { title: t('Edit'), path: paths.dashboard.termsofusedoc.edit },
                                    //   ],
                                    // },
                                ],
                                permissions?.UEMSettings
                            ),
                        },
                    ],
                    permissions?.UEMSettings
                ),
            },

            // TeamXcel//
            // ----------------------------------------------------------------------
            {
                // subheader: t('UEM Settings'),
                icon: 'solar:user-bold-duotone',
                items: PermissionsMapper(
                    [
                        {
                            title: t('TeamXCEL'),
                            permissionType: 'TeamXCEL',
                            path: 'dashboard/team-xcel',
                            icon: <Iconify icon="solar:user-bold-duotone" />,
                            info: (
                                <Label color="info">
                                    <Iconify
                                        icon="solar:bell-bing-bold-duotone"
                                        style={{ marginRight: '5px' }}
                                    />
                                    NEW
                                </Label>
                            ),
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Dashboard'),
                                        permissionType: 'TeamxcelDashboard',
                                        path: paths.dashboard.teamxceldashboard.root,
                                        children: PermissionsMapper(
                                            [
                                                // {
                                                //     title: t('Details'),
                                                //     path: paths.dashboard
                                                //         .dashboardandreports.root,
                                                //     permissionType: READ,
                                                // },
                                                {
                                                    title: t('Dashboard'),
                                                    path: paths.dashboard.teamxceldashboard.root,
                                                    permissionType: READ,
                                                },
                                                // {
                                                //     title: t('Edit'),
                                                //     path: paths.dashboard
                                                //         .dashboardandreports.edit,
                                                //     permissionType: EDIT,
                                                // },
                                                // {
                                                //     title: t('Reports'),
                                                //     path: paths.dashboard.teamxceldashboard.reports,
                                                //     permissionType: READ,
                                                // },
                                            ],
                                            permissions?.TeamXCEL?.TeamxcelDashboardChild
                                        ),
                                    },
                                    {
                                        title: t('Reports'),
                                        permissionType: 'TeamxcelReports',
                                        path: paths.dashboard.teamxcelreports.root,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('Employee Reports'),
                                                    path: paths.dashboard.teamxcelreports
                                                        .employeereport,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('Team Reports'),
                                                    path: paths.dashboard.teamxcelreports
                                                        .teamreport,
                                                    permissionType: WRITE,
                                                },
                                                {
                                                    title: t('Project Reports'),
                                                    path: paths.dashboard.teamxcelreports
                                                        .projectreport,
                                                    permissionType: EDIT,
                                                },
                                                {
                                                    title: t('Absent Reports'),
                                                    path: paths.dashboard.teamxcelreports
                                                        .absentreport,
                                                    permissionType: WRITE,
                                                },
                                                {
                                                    title: t('Clockin/out Reports'),
                                                    path: paths.dashboard.teamxcelreports
                                                        .clockinoutreport,
                                                    permissionType: WRITE,
                                                },
                                            ],
                                            permissions?.TeamXCEL?.TeamxcelReportsChild
                                        ),
                                    },
                                    {
                                        title: t('Office'),
                                        permissionType: 'Office',
                                        path: paths.dashboard.office.root,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('Office Hierarchy'),
                                                    path: paths.dashboard.office.root,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.office.create,
                                                    permissionType: WRITE,
                                                },
                                                // {
                                                //     title: t('Edit'),
                                                //     path: paths.dashboard.office.edit,
                                                //     permissionType: EDIT,
                                                // },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.office.list,
                                                    permissionType: READ,
                                                },
                                            ],
                                            permissions?.TeamXCEL?.OfficeChild
                                        ),
                                    },
                                    {
                                        title: t('Enterprise Project'),
                                        permissionType: 'EnterpriseProject',
                                        path: paths.dashboard.enterpriseproject.root,
                                        children: PermissionsMapper(
                                            [
                                                // {
                                                //     title: t('Details'),
                                                //     path: paths.dashboard.enterpriseproject.root,
                                                //     permissionType: READ,
                                                // },
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.enterpriseproject.create,
                                                    permissionType: WRITE,
                                                },
                                                // {
                                                //     title: t('Edit'),
                                                //     path: paths.dashboard.enterpriseproject.edit,
                                                //     permissionType: EDIT,
                                                // },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.enterpriseproject.list,
                                                    permissionType: READ,
                                                },
                                                // {
                                                //     title: t('Employee List'),
                                                //     path: paths.dashboard.enterpriseproject.employee,
                                                //     permissionType: READ,
                                                // },
                                            ],
                                            permissions?.TeamXCEL?.EnterpriseProjectChild
                                        ),
                                    },

                                    {
                                        title: t('Employee'),
                                        permissionType: 'Employee',
                                        path: paths.dashboard.employee.root,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.employee.list,
                                                    permissionType: READ,
                                                },

                                                // {
                                                //     title: t('Details'),
                                                //     path: paths.dashboard.employee.root,
                                                //     permissionType: READ,
                                                // },
                                                // {
                                                //     title: t('Timesheet'),
                                                //     path: paths.dashboard.employee.timesheet,
                                                //     permissionType: READ,
                                                // },
                                                // {
                                                //     title: t('Create'),
                                                //     path: paths.dashboard.employee.create,
                                                //     permissionType: WRITE,
                                                // },
                                                // {
                                                //     title: t('Edit'),
                                                //     path: paths.dashboard.employee.edit,
                                                //     permissionType: EDIT,
                                                // },
                                            ],
                                            permissions?.TeamXCEL?.EmployeeChild
                                        ),
                                    },
                                    {
                                        title: t('Project Assignment'),
                                        permissionType: 'Employee',
                                        path: paths.dashboard.employee.projectassign,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.employee.projectassign,
                                                    permissionType: READ,
                                                },
                                            ],
                                            permissions?.TeamXCEL?.EmployeeChild
                                        ),
                                    },

                                    {
                                        title: t('Geo Fencing'),
                                        permissionType: 'ProjectTeams',
                                        path: paths.dashboard.teamxcelgeofence.root,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.teamxcelgeofence.create,
                                                    permissionType: WRITE,
                                                },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.teamxcelgeofence.list,
                                                    permissionType: READ,
                                                },
                                            ],
                                            permissions?.TeamXCEL?.ProjectTeamsChild
                                        ),
                                    },

                                    {
                                        title: t('Project Teams'),
                                        permissionType: 'ProjectTeams',
                                        path: paths.dashboard.projectteams.root,
                                        children: PermissionsMapper(
                                            [
                                                // {
                                                //     title: t('Details'),
                                                //     path: paths.dashboard.projectteams.root,
                                                //     permissionType: READ,
                                                // },
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.projectteams.create,
                                                    permissionType: WRITE,
                                                },
                                                {
                                                    title: t('Edit'),
                                                    path: paths.dashboard.projectteams.edit,
                                                    permissionType: EDIT,
                                                },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.projectteams.list,
                                                    permissionType: READ,
                                                },
                                                // {
                                                //     title: t('Associate Project'),
                                                //     path: paths.dashboard.projectassignment.associate,
                                                //     permissionType: READ,
                                                // },
                                            ],
                                            permissions?.TeamXCEL?.ProjectTeamsChild
                                        ),
                                    },

                                    // {
                                    //   title: t('Terms of Use Doc'),
                                    //   path: paths.dashboard.termsofusedoc.root,
                                    //   children: [
                                    //     { title: t('Details'), path: paths.dashboard.termsofusedoc.root },
                                    //     { title: t('List'), path: paths.dashboard.termsofusedoc.list },
                                    //     { title: t('Create'), path: paths.dashboard.termsofusedoc.create },
                                    //     { title: t('Edit'), path: paths.dashboard.termsofusedoc.edit },
                                    //   ],
                                    // },
                                ],
                                permissions?.TeamXCEL
                            ),
                        },
                    ],
                    permissions?.TeamXCEL
                ),
            },

            // {
            //   icon: 'solar:settings-bold-duotone',
            //   items: PermissionsMapper(
            //     [
            //       {
            //         title: t('Tools'),
            //         permissionType: 'Tools',
            //         path: paths.dashboard.tools.root,
            //         icon: <Iconify icon="solar:settings-minimalistic-bold-duotone" />,
            //         children: PermissionsMapper(
            //           [
            //             {
            //               title: t('Remote Control'),
            //               path: paths.dashboard.tools.root,
            //               permissionType: EDIT,
            //             },
            //             {
            //               title: t('Notification'),
            //               path: paths.dashboard.tools.notification,
            //               permissionType: EDIT,
            //             },
            //           ],
            //           permissions?.Tools?.ToolsChild
            //         ),
            //       },
            //     ],
            //     permissions?.Tools
            //   ),
            // },
            // {
            //   icon: 'solar:settings-bold-duotone',
            //   items: PermissionsMapper(
            //     [
            //       {
            //         title: t('Reports'),
            //         permissionType: 'Report',
            //         path: paths.dashboard.reports.root,
            //         icon: <Iconify icon="solar:book-bookmark-bold-duotone" />,
            //         children: PermissionsMapper(
            //           [{ title: t('Report'), path: paths.dashboard.reports.root, permissionType: READ }],
            //           permissions?.Reports?.ReportChild
            //         ),
            //       },
            //     ],
            //     permissions?.Reports
            //   ),
            // },
        ];
    }, [t, permissions]);

    return data;
}
